/** @jsxImportSource @emotion/react */
import {
  Button,
  Card,
  Dropdown,
  DropdownProps,
  Modal,
  Select,
  Table,
  TableProps,
} from "antd";
import React, { useState } from "react";
import { Category } from "../lib/Api";
import { AddCategoryModal } from "./AddCategoryModal";
import { useDep } from "./DependencyContainerProvider";
import { MoreIcon } from "./Icons";
import { useCategories } from "./Providers";
import { useOops } from "./handleError";

export interface CategoriesPageProps {
  className?: string;
}

export function CategoriesPage(
  props: React.PropsWithChildren<CategoriesPageProps>,
) {
  const { api } = useDep();
  const [categories, setCategories] = useCategories();
  const [modal, contextHolder] = Modal.useModal();
  const [addModal, setAddModal] = useState<boolean>(false);
  const oops = useOops();

  const columns: TableProps<Category>["columns"] = [
    {
      title: "Category name",
      render: (_, record) => record.name,
    },
    {
      title: "Type",
      render: (_, record) => {
        return (
          <Select
            value={record.status}
            onChange={(value) => {
              api
                .updateCategory(record.id, { status: value })
                .then((resp) => {
                  const index = categories.findIndex((c) => c.id === record.id);
                  const newCategories = [...categories];
                  const category = { ...record, status: value };
                  newCategories[index] = category;
                  setCategories(newCategories);
                })
                .catch(oops);
            }}
            variant="filled"
            css={{ width: "8rem" }}
            options={[
              { label: "Expense", value: "expense" },
              { label: "Income", value: "income" },
            ]}
          ></Select>
        );
      },
    },
    {
      title: "Actions",
      width: 60,
      render: (_, record) => {
        const menu: DropdownProps["menu"] = {
          items: [
            {
              key: "remove",
              label: "Remove",
              onClick: () => {
                modal.confirm({
                  icon: null,
                  content: "Are you sure you want to remove this category?",
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => {
                    api
                      .destroyCategory(record.id)
                      .then(() => {
                        const index = categories.findIndex(
                          (c) => c.id === record.id,
                        );
                        const newCategories = [...categories];
                        if (index !== -1) {
                          newCategories.splice(index, 1);
                        }
                        setCategories(newCategories);
                      })
                      .catch(oops);
                  },
                  okText: "Yes, remove",
                  cancelButtonProps: {
                    type: "text",
                  },
                });
              },
            },
          ],
        };

        return (
          <div css={{ display: "flex", justifyContent: "center" }}>
            <Dropdown trigger={["click"]} menu={menu}>
              <Button size="small">
                <MoreIcon />
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const onClickAdd = () => {
    setAddModal(true);
  };

  return (
    <Card>
      {contextHolder}
      <div css={{ display: "flex", justifyContent: "center" }}>
        <div css={{ maxWidth: "60rem", width: "100%" }}>
          <div css={{ padding: "1rem 0" }}>
            <Button onClick={onClickAdd} type="primary">
              + Add Category
            </Button>
          </div>
          <Table
            pagination={{ pageSize: 50 }}
            bordered
            size="small"
            dataSource={categories}
            columns={columns}
          />
        </div>
      </div>
      <AddCategoryModal
        open={addModal}
        onCancel={() => {
          setAddModal(false);
        }}
        onDone={() => {
          setAddModal(false);
        }}
      />
    </Card>
  );
}
