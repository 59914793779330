/** @jsxImportSource @emotion/react */
import React from "react";
import { useLoadBankAccounts } from "./hooks/useLoadBankAccounts";
import { useLoadCategories } from "./hooks/useLoadCategories";
import { useLoadMe } from "./hooks/useLoadMe";
import { useLoadUpgradeUrl } from "./hooks/useLoadUpgradeUrl";
import { useLoadUsers } from "./hooks/useLoadUsers";

export interface ApplicationLoadersProps {
  className?: string;
}

export function ApplicationLoaders(
  props: React.PropsWithChildren<ApplicationLoadersProps>,
) {
  useLoadBankAccounts();
  useLoadCategories();
  useLoadMe();
  useLoadUpgradeUrl();
  useLoadUsers();

  return <>{props.children}</>;
}
