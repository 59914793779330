/** @jsxImportSource @emotion/react */
import "chart.js/auto";
import numeral from "numeral";
import React from "react";
import { ChartProps, Doughnut } from "react-chartjs-2";
import { CategoryStats } from "../lib/Api";
import { AppColor } from "./design/colors";
import { appFontFamily } from "./design/fonts";
import { isNumber } from "./lib";

export interface CategoryDonutProps {
  className?: string;
  stats: CategoryStats;
  legend?: boolean;
  tooltips?: boolean;
  hover?: boolean;
}

const colors = [
  AppColor.Brown3,
  AppColor.Brown5,
  AppColor.Brown7,
  AppColor.Brown9,
  AppColor.Brown11,
  AppColor.Brown13,

  AppColor.Green3,
  AppColor.Green5,
  AppColor.Green7,
  AppColor.Green9,
  AppColor.Green11,
  AppColor.Green13,
];

export function CategoryDonut(
  props: React.PropsWithChildren<CategoryDonutProps>,
) {
  const { totals, metadata } = props.stats;
  const keys = Object.keys(props.stats.totals).map((t) => parseInt(t, 10));
  const values = keys.map((k) => totals[k]);
  const labels = keys.map((k) => metadata[k]);

  const data: ChartProps<"doughnut">["data"] = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  const options: ChartProps<"doughnut">["options"] = {
    responsive: true,
    plugins: {
      legend: {
        display: props.legend ?? false,
        position: "right",
        labels: {
          font: {
            family: appFontFamily,
            size: 15,
          },
        },
      },
      tooltip: {
        enabled: props.tooltips ?? false,
        callbacks: {
          label: function (item) {
            if (!isNumber(item.raw)) {
              return item.label;
            }
            return numeral(item.raw / 100).format("$0,0.00");
          },
        },
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}
