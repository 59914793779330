import { useDep } from "./DependencyContainerProvider";
import { useTokenSetter } from "./TokenProvider";
import { storeToken } from "./storage";

interface LoginFn {
  (email: string, password: string, remember?: boolean): Promise<void>;
}

function useLogin(): LoginFn {
  const { api } = useDep();
  const setToken = useTokenSetter();

  return (email, password, remember) => {
    return api.createToken({ email, password }).then((resp) => {
      storeToken(resp.token, remember);
      setToken(resp.token);
    });
  };
}

export default useLogin;
