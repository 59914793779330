/** @jsxImportSource @emotion/react */
import { Button, Card, List, Modal, Typography } from "antd";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { BankAccount, MonthlyReport } from "../lib/Api";
import { BankAccountDropdown } from "./BankAccountDropdown";
import { useDep } from "./DependencyContainerProvider";
import { MonthlyReportPreview } from "./MonthlyReportPreview";
import { NewBankAccountModal } from "./NewBankAccountModal";
import { useActiveBankAccount, useBankAccounts } from "./Providers";
import { useOops } from "./handleError";
import { useParsedSearch } from "./hooks/useParsedSearch";
import { useReportUrlFilters } from "./hooks/useReportUrlFilters";

export interface ReportsPageProps {
  className?: string;
}

export interface AddAccountFormValues {
  name: string;
}

export function ReportsPage(_props: React.PropsWithChildren<ReportsPageProps>) {
  const params = useParsedSearch();
  const filters = useReportUrlFilters(params);
  const [modal, contextHolder] = Modal.useModal();
  const [bankAccountModal, setBankAccountModal] = useState<boolean>(false);
  const [bankAccounts] = useBankAccounts();
  const [bankAccountId, setBankAccountId] = useActiveBankAccount();
  const [dates, setDates] = useState<string[]>(filters.dates ?? []);
  const { api } = useDep();
  const [reports, setReports] = useState<MonthlyReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const navigate = useNavigate();
  const oops = useOops();

  useEffect(() => {
    if (filters.bankAccountId) {
      setBankAccountId(filters.bankAccountId);
    }
    setDates(filters.dates ?? []);
  }, [filters]);

  useEffect(() => {
    if (!bankAccountId) {
      return;
    }
    setLoading(true);
    api
      .getMonthlyReports({ bank_account_id: bankAccountId, dates })
      .then((resp) => {
        setReports(resp.monthly_reports);
        setEmpty(resp.monthly_reports.length === 0);
      })
      .catch(oops)
      .finally(() => {
        setLoading(false);
      });
  }, [bankAccountId]);

  // Sets the initial bank account ID from either the URL filters or
  // from the first bank account ID
  useEffect(() => {
    if (!bankAccountId) {
      setBankAccountId(filters.bankAccountId ?? bankAccounts[0]?.id);
    }
  }, [bankAccounts]);

  const cards = reports.map((report) => {
    return (
      <List.Item>
        <MonthlyReportPreview
          allowRemove
          onRemove={() => {
            const newReports = [...reports];
            const index = newReports.findIndex((r) => r.id === report.id);
            if (index === -1) {
              return;
            }
            newReports.splice(index, 1);
            setReports(newReports);
            if (newReports.length === 0) {
              setEmpty(true);
            }
          }}
          css={{ flex: 1, width: "100%" }}
          report={report}
        />
      </List.Item>
    );
  });

  const onChangeAccount = (id: number) => {
    navigate(`/overview?bankAccountId=${id}`);
  };

  const onModalDone = (bankAccount?: BankAccount) => {
    setBankAccountModal(false);
    if (!bankAccount) {
      return;
    }

    const onOk = () => {
      const query = QueryString.stringify({ bankAccountId: bankAccount.id });
      navigate(`/upload?${query}`);
    };

    modal.success({
      icon: null,
      title: <>🎉 Woohoo!</>,
      content: (
        <Typography.Text>
          You've added a new account. Go to the upload page now to start
          uploading your transactions.
        </Typography.Text>
      ),
      onOk: onOk,
      onCancel: onOk,
      okText: "Done",
    });
  };

  const account = bankAccounts.find((a) => a.id === bankAccountId);

  return (
    <Fade>
      <Card>
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "2rem",
          }}
        >
          {contextHolder}
          <div css={{ maxWidth: "60rem", width: "100%", padding: "0 1rem" }}>
            <div
              css={{
                paddingBottom: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div>
                <Typography.Text
                  css={{ display: "block", marginBottom: 8 }}
                  // strong
                >
                  Choose an account
                </Typography.Text>

                <BankAccountDropdown
                  value={bankAccountId}
                  onChange={onChangeAccount}
                  css={{ width: "14rem" }}
                  bankAccounts={bankAccounts}
                />
                {dates.length > 0 && (
                  <Typography.Link
                    css={{ marginLeft: "1rem" }}
                    onClick={() => {
                      navigate(`/overview?bankAccountId=${bankAccountId}`);
                    }}
                  >
                    Clear date filter
                  </Typography.Link>
                )}
              </div>

              <Button
                type="primary"
                onClick={() => {
                  setBankAccountModal(true);
                }}
              >
                + Add account
              </Button>
            </div>

            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: ".25rem",
              }}
            >
              <List size="large">{cards}</List>

              {empty && (
                <div css={{ textAlign: "center" }}>
                  <Typography.Title level={3}>
                    {account
                      ? `No reports created yet for ${account.name} bank account`
                      : "No reports created yet"}
                  </Typography.Title>
                  <Typography.Text>
                    Go to the <Link to="/upload">upload page</Link> to start
                    creating reports for this account
                  </Typography.Text>
                </div>
              )}
            </div>
          </div>

          <NewBankAccountModal open={bankAccountModal} onDone={onModalDone} />
        </div>
      </Card>
    </Fade>
  );
}
