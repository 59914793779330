/** @jsxImportSource @emotion/react */
import { Button, Modal, Typography, Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import React, { useEffect } from "react";
import imgCsv from "../images/csv-example.png";
import { CsvIcon, HelpIcon } from "./Icons";
import { LoadingSpinner } from "./LoadingSpinner";
import { AppColor } from "./design/colors";
import { useUpload } from "./hooks/useUpload";

const Dragger = Upload.Dragger;

// RawTransaction is a data structure that depends on the implicit contract
// of the API processor. The API, after processing the CSV, should put an array
// of these data structures into a file that is then polled from the client.
export interface RawTransaction {
  date: string | null;
  amount: number | null;
  description: string | null;
  category_id: number | null;
}

export interface UploaderProps {
  className?: string;
  onLoading(loading: boolean): any;
  onGetResults(transactions: RawTransaction[]): any;
}

function isFile(f: any): f is File {
  return f instanceof File;
}

export function Uploader(props: React.PropsWithChildren<UploaderProps>) {
  const [uploading, results, upload] = useUpload();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (!!results) {
      props.onGetResults(results);
    }
  }, [results]);

  useEffect(() => {
    props.onLoading(uploading);
  }, [uploading]);

  const onChange = (info: UploadChangeParam) => {
    if (!isFile(info.file)) {
      return;
    }
    upload(info.file);
  };

  const onClickHelp = () => {
    modal.info({
      width: 600,
      icon: null,
      okText: "Done",
      title: "How to generate a CSV file of bank transactions",
      content: (
        <>
          <Typography.Title level={5}>
            Step 1: Access Your Online Banking Account
          </Typography.Title>
          <Typography.Paragraph>
            <ul>
              <li>
                Go to your bank's website and log into your online banking
                account using your username and password
              </li>

              <li>
                Once logged in, find the section of the website where you can
                view your account transactions. This is usually labeled as
                "Transactions," "Account Activity," or similar.
              </li>
            </ul>
          </Typography.Paragraph>

          <Typography.Title level={5}>
            Step 2: Filter Transactions for the Specific Month
          </Typography.Title>
          <Typography.Paragraph>
            <ul>
              <li>
                Use the date filter options to select the specific month you
                want to download transactions for. Set the start date to the
                first day of the month and the end date to the last day of the
                month.
              </li>
              <li>
                Apply the filter to view only the transactions within the
                selected date range.
              </li>
            </ul>
          </Typography.Paragraph>

          <Typography.Title level={5}>
            Step 3: Download and Structure the CSV File
          </Typography.Title>
          <Typography.Paragraph>
            <ul>
              <li>
                Look for an option to download or export your transactions. This
                option is typically available on the transactions page. Choose
                the CSV format for the download.
              </li>

              <li>
                Once the file is downloaded, open it using a spreadsheet program
                like Microsoft Excel, Google Sheets, or any other CSV editor.
              </li>

              <li>
                Delete any columns that are not needed, leaving only the columns
                for Date, Amount, and Description. Do not label the columns in
                the sheet.
                <ul>
                  <li>Date: The date of the transaction.</li>
                  <li>Amount: The amount of the transaction.</li>
                  <li>Description: The description of the transaction.</li>
                </ul>
              </li>

              <li>
                Save the edited file to your computer and upload it on this
                page.
              </li>
            </ul>
          </Typography.Paragraph>
        </>
      ),
    });
  };

  if (!!results) {
    return (
      <div css={{ padding: "2rem", textAlign: "center" }}>
        <Typography.Text>
          Your file has been successfully uploaded.
        </Typography.Text>
      </div>
    );
  }

  return (
    <div css={{ width: "100%" }}>
      {contextHolder}

      {uploading ? (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <LoadingSpinner css={{ color: AppColor.Green8 }} size={48} />
        </div>
      ) : (
        <>
          <Typography.Title level={4} css={{ textAlign: "center" }}>
            Upload your bank activity for the month
          </Typography.Title>
          <Dragger
            maxCount={1}
            onChange={onChange}
            beforeUpload={() => false}
            style={{ borderWidth: 3, backgroundColor: AppColor.Green1 }}
            accept=".csv,text/csv,application/csv,text/plain"
          >
            <Typography.Text type="secondary">
              <CsvIcon size={64} />
              {/* <UploadIcon size={32} /> */}
              <Typography.Title level={4} css={{ paddingTop: "1rem" }}>
                <div>Click here or drag your CSV file</div>
              </Typography.Title>
            </Typography.Text>
            <ul>
              <li>
                <Typography.Text>File must be a .CSV</Typography.Text>
              </li>
              <li>
                <Typography.Text>File must be less than 100kb</Typography.Text>
              </li>
              <li>
                <Typography.Text>
                  File should only contain the fields: date, amount, and
                  description
                </Typography.Text>
              </li>
            </ul>
            <div css={{ padding: "1rem" }}>
              <img
                height={96}
                src={imgCsv}
                css={{
                  boxShadow: "0px 6px 12px rgba(100,100,100,0.1)",
                  borderRadius: 12,
                }}
              />
            </div>
          </Dragger>
          <Button type="link" onClick={onClickHelp}>
            CSV help <HelpIcon size={18} />
          </Button>
        </>
      )}
    </div>
  );
}
