export const AppColor = {
  Green1: "#f0fff7",
  Green2: "#d5f0e4",
  Green3: "#bbe2d4",
  Green4: "#a4d3c5",
  Green5: "#8ec9b7",
  Green6: "#69b39e",
  Green7: "#4c9d86",
  Green8: "#368770",
  Green9: "#26725c",
  Green10: "#1a5c49",
  Green11: "#114636",
  Green12: "#0a3125",
  Green13: "#051b14",
  Green14: "#010504",

  Brown1: "#fffefc",
  Brown2: "#ffebd7",
  Brown3: "#ffdbb1",
  Brown4: "#ffcb8b",
  Brown5: "#ffbd63",
  Brown6: "#ffa62e",
  Brown7: "#fa9300",
  Brown8: "#d4800a",
  Brown9: "#ae6c12",
  Brown10: "#885818",
  Brown11: "#634219",
  Brown12: "#3d2b15",
  Brown13: "#17110a",
};
