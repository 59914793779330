import { useEffect } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useOops } from "../handleError";
import { useCategories } from "../Providers";

// This loader has a dependency on the BankAccountsProvider. It calls the API
// to fetch all bank accounts and loads up the array.
export function useLoadCategories() {
  const { api } = useDep();
  const [_, setCategories] = useCategories();
  const oops = useOops();

  useEffect(() => {
    api
      .getCategories({})
      .then((resp) => {
        setCategories(resp.categories);
      })
      .catch(oops);
  }, []);
}
