import React, { createContext, useContext, useState, ReactNode } from "react";

interface Settings {
  darkMode: boolean;
}

interface SettingsContextType {
  settings: Settings;
  setSetting: <T extends keyof Settings>(key: T, value: Settings[T]) => void;
  getSetting: <T extends keyof Settings>(key: T) => Settings[T] | null;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined,
);

const SETTINGS_KEY = "appSettings";

function getSetting<T extends keyof Settings>(key: T): Settings[T] | null {
  const settings = localStorage.getItem(SETTINGS_KEY);
  if (settings) {
    const parsedSettings: Settings = JSON.parse(settings);
    return parsedSettings[key];
  }
  return null;
}

function setSetting<T extends keyof Settings>(
  key: T,
  value: Settings[T],
): void {
  const settings = localStorage.getItem(SETTINGS_KEY);
  let parsedSettings: Settings = settings
    ? JSON.parse(settings)
    : ({} as Settings);
  parsedSettings[key] = value;
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(parsedSettings));
}

const defaults: Settings = {
  darkMode: false,
};

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<Settings>(() => {
    const savedSettings = localStorage.getItem(SETTINGS_KEY);
    return savedSettings ? JSON.parse(savedSettings) : defaults;
  });

  const setSettingHandler = <T extends keyof Settings>(
    key: T,
    value: Settings[T],
  ) => {
    setSetting(key, value);
    setSettings((prev) => ({ ...prev, [key]: value }));
  };

  const getSettingHandler = <T extends keyof Settings>(key: T) => {
    return getSetting(key);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSetting: setSettingHandler,
        getSetting: getSettingHandler,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
