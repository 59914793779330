/** @jsxImportSource @emotion/react */
import { isString } from "lodash";
import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import en from "../locales/en/messages.json";
import { useUserLocale } from "./Providers";

export interface LocalesProps {
  className?: string;
}

export type LocaleKey = keyof typeof en;

const KEYS = new Set(Object.keys(en));

export function isLocaleKey(key: any): key is LocaleKey {
  return isString(key) && KEYS.has(key);
}

const DEFAULT_LOCALE = "en";

export function Locales(props: React.PropsWithChildren<LocalesProps>) {
  let [_locale] = useUserLocale();

  const messages = useMemo(() => {
    return { en };
  }, []);

  let locale: string;
  if (_locale && (messages as any)[_locale]) {
    locale = _locale;
  } else {
    locale = DEFAULT_LOCALE;
  }

  const localeMessages = (messages as any)[locale];

  return (
    <IntlProvider locale={locale} messages={localeMessages}>
      {props.children}
    </IntlProvider>
  );
}
