/** @jsxImportSource @emotion/react */
import React from "react";
import { Category } from "../lib/Api";
import { Select, SelectProps } from "antd";

export interface CategoryInputProps extends Omit<SelectProps, "options"> {
  className?: string;
  categories: Category[];
}

export function CategoryInput(
  props: React.PropsWithChildren<CategoryInputProps>,
) {
  return (
    <Select
      {...props}
      options={props.categories.map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      })}
    />
  );
}
