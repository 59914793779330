/** @jsxImportSource @emotion/react */

import { Layout, Menu, Modal, Typography } from "antd";
import useToken from "antd/es/theme/useToken";
import React, { CSSProperties } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingsIcon } from "./Icons";
import { MunkLogo } from "./MunkLogo";
import { UpgradeBanner } from "./UpgradeBanner";
import useLogout from "./useLogout";

const { Header } = Layout;

export interface AppNavbarProps {
  className?: string;
}

const linkStyle: CSSProperties = {
  fontWeight: 500,
};

export function AppNavbar(props: React.PropsWithChildren<AppNavbarProps>) {
  const [_, token] = useToken();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const logout = useLogout();
  const location = useLocation();

  return (
    <Header
      css={{
        background: token.colorBgContainer,
        display: "flex",
        alignItems: "center",
      }}
    >
      {contextHolder}
      <Typography.Title
        color="#fff"
        style={{ margin: 0, fontSize: "1rem", fontWeight: 700 }}
        level={3}
      >
        <MunkLogo
          size={80}
          css={{
            border: `10px solid ${token.colorBgContainer}`,
            position: "relative",
            zIndex: 1,
            top: 6,
          }}
        />
      </Typography.Title>

      <Menu
        css={{ flexGrow: 1 }}
        selectedKeys={[location.pathname]}
        activeKey={location.pathname}
        mode="horizontal"
        items={[
          {
            key: "/overview",
            label: "Overview",
            style: linkStyle,
            onClick: () => navigate("/overview"),
          },
          {
            key: "/upload",
            label: "Upload",
            style: linkStyle,
            onClick: () => navigate("/upload"),
          },
          {
            key: "submenu",
            type: "submenu",
            label: (
              <SettingsIcon css={{ position: "relative", top: 3 }} size={18} />
            ),
            children: [
              {
                key: "/account",
                label: "Account",
                style: linkStyle,
                onClick: () => navigate("/account"),
              },
              {
                key: "/categories",
                label: "Categories",
                style: linkStyle,
                onClick: () => navigate("/categories"),
              },
              {
                key: "logout",
                label: "Logout",
                style: linkStyle,
                onClick: () => {
                  modal.confirm({
                    icon: null,
                    cancelButtonProps: {
                      type: "text",
                    },
                    content: (
                      <Typography.Text>
                        Are you sure you want to logout?
                      </Typography.Text>
                    ),
                    okText: "Yes, logout",
                    onOk: logout,
                  });
                },
              },
            ],
          },
        ]}
      />

      <UpgradeBanner />
    </Header>
  );
}
