import { createBrowserRouter } from "react-router-dom";
import { AccountPage } from "./AccountPage";
import { ApplicationLayout } from "./ApplicationLayout";
import { CategoriesPage } from "./CategoriesPage";
import { CheckoutSuccessPage } from "./CheckoutSuccess";
import { HomePage } from "./HomePage";
import { JoinPage } from "./JoinPage";
import { LoginPage } from "./LoginPage";
import { LogoutPage } from "./LogoutPage";
import { Main } from "./Main";
import { PasswordResetPage } from "./PasswordResetPage";
import { ActiveBankAccountProvider } from "./Providers";
import { PublicLayout } from "./PublicLayout";
import { ReportsPage } from "./ReportsPage";
import { UploadPage } from "./UploadPage";
import { WelcomePage } from "./WelcomePage";

export function createApplicationRouter() {
  return createBrowserRouter([
    {
      path: "",
      element: <Main />,
      children: [
        {
          path: "",
          element: <PublicLayout />,
          children: [
            {
              path: "/join",
              element: <JoinPage />,
            },
            {
              path: "/login",
              element: <LoginPage />,
            },
            {
              path: "/",
              element: <HomePage />,
            },
            {
              path: "/logout",
              element: <LogoutPage />,
            },
            {
              path: "/password-reset",
              element: <PasswordResetPage />,
            },
          ],
        },
        {
          path: "",
          element: <ApplicationLayout />,
          children: [
            {
              path: "/welcome",
              element: <WelcomePage />,
            },
            {
              path: "/categories",
              element: <CategoriesPage />,
            },
            {
              path: "/checkout/success",
              element: <CheckoutSuccessPage />,
            },
            {
              path: "/upload",
              element: (
                <ActiveBankAccountProvider>
                  <UploadPage />
                </ActiveBankAccountProvider>
              ),
            },
            {
              path: "/overview",
              element: (
                <ActiveBankAccountProvider>
                  <ReportsPage />,
                </ActiveBankAccountProvider>
              ),
            },
            {
              path: "/account",
              element: <AccountPage />,
            },
          ],
        },
      ],
    },
  ]);
}
