/** @jsxImportSource @emotion/react */
import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export interface LogoutPageProps {
  className?: string;
}

export function LogoutPage(props: React.PropsWithChildren<LogoutPageProps>) {
  return (
    <div
      className={props.className}
      css={{ textAlign: "center", paddingTop: "2rem" }}
    >
      <Typography.Title level={3}>👋 You're logged out</Typography.Title>
      <Typography.Text>
        See you next time. You can log back in{" "}
        <strong>
          <Link to="/login">here</Link>.
        </strong>
      </Typography.Text>
    </div>
  );
}
