import axios from "axios";
import axiosRetry from "axios-retry";
import { useState } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useOops } from "../handleError";

export interface UploadFn {
  (file: File): any;
}

type ReturnValue = [boolean, any, UploadFn];

export function useUpload(): ReturnValue {
  const [uploading, setUploading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);
  const { api } = useDep();
  const oops = useOops();

  const uploadFn = async (file: File) => {
    try {
      setUploading(true);
      const { upload, download } = await api.createTransactionImport({});
      const formData = new FormData();
      Object.keys(upload.fields).forEach((key) => {
        formData.append(key, upload.fields[key]);
      });
      formData.append("file", file);
      await axios.post(upload.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await api.processTransactionImport({
        bucket: upload.bucket,
        key: upload.key,
      });
      await poll(download.url);
    } catch (e) {
      oops(e);
    } finally {
      setUploading(false);
    }
  };

  const poll = (url: string): Promise<void> => {
    const instance = axios.create();
    // Poll for up to a minute
    axiosRetry(instance, {
      retries: 5,
      retryCondition: () => true, // always retry no matter the condition
      retryDelay: () => 1000,
    });
    return instance
      .get(url)
      .then((resp) => setData(resp.data))
      .catch(oops);
  };

  return [uploading, data, uploadFn];
}
