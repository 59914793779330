/** @jsxImportSource @emotion/react */
import {
  Button,
  Dropdown,
  DropdownProps,
  message,
  Modal,
  Row,
  Statistic,
  Tooltip,
} from "antd";
import useToken from "antd/es/theme/useToken";
import dayjs from "dayjs";
import numeral from "numeral";
import React, { useState } from "react";
import { MonthlyReport } from "../lib/Api";
import { CategoryBreakdownTable } from "./CategoryBreakdownTable";
import { CategoryDonut } from "./CategoryDonut";
import { useDep } from "./DependencyContainerProvider";
import { MoreIcon } from "./Icons";
import { useOops } from "./handleError";

export interface MonthlyReportPreviewProps {
  className?: string;
  report: MonthlyReport;
  obfuscate?: boolean;
  onRemove?(): any;
  allowRemove?: boolean;
}

export function MonthlyReportPreview(
  props: React.PropsWithChildren<MonthlyReportPreviewProps>,
) {
  const { api } = useDep();
  const { report } = props;
  const [_, token] = useToken();
  const amount = numeral(report.amount_in_cents / 100).format("$0,0.00");
  const month = dayjs(report.date).format("MMM, YYYY");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();
  const oops = useOops();

  const numCats = Object.keys(report.category_stats?.totals ?? {}).length;

  const getAmountColor = () => {
    if (report.amount_in_cents > 0) {
      return token.colorSuccess;
    } else if (report.amount_in_cents < 0) {
      return token.colorError;
    }
    return token.colorText;
  };

  const menu: DropdownProps["menu"] = {
    items: [
      {
        key: "clear",
        label: "Clear this month",
        onClick: () => {
          modal.confirm({
            icon: null,
            cancelButtonProps: {
              type: "text",
            },
            okButtonProps: {
              danger: true,
            },
            okText: "Yes, clear report",
            onOk: () => {
              api
                .deleteReports(report.id)
                .then((resp) => {
                  message.success("Report deleted");
                  props.onRemove?.();
                })
                .catch(oops);
            },
            title: "Please confirm",
            content: "Are you sure you want to reset this months data?",
          });
        },
      },
    ],
  };

  return (
    <div className={props.className}>
      {contextHolder}
      <Row
        css={{
          display: "flex",
          gap: "3rem",
          filter: props.obfuscate ? "blur(5px)" : undefined,
        }}
      >
        <Statistic css={{ flex: 1 }} title="Month" value={month} />
        <Statistic
          css={{ flex: 1 }}
          title="Outflow"
          value={props.obfuscate ? "$x,xxx" : amount}
          valueStyle={{ color: getAmountColor() }}
        />
        <Statistic
          css={{ flex: 1 }}
          title="# of transactions"
          value={props.obfuscate ? "xx" : report.transaction_count}
        />
        {/* <Statistic
          css={{ flex: 1 }}
          title="Num. categories"
          value={props.obfuscate ? "xx" : numCats}
        /> */}
        {report.category_stats && (
          <div css={{ flex: 1 }}>
            <Tooltip mouseEnterDelay={0.5} title="View spend by category">
              <div
                role="button"
                onClick={() => {
                  setShowModal(true);
                }}
                css={{
                  height: 60,
                  width: 60,
                  borderRadius: 12,
                  cursor: "pointer",
                }}
              >
                <CategoryDonut stats={report.category_stats} />
              </div>
            </Tooltip>
          </div>
        )}

        {props.allowRemove && (
          <div css={{ flex: 1 }}>
            <Dropdown menu={menu} placement="bottomRight">
              <Button type="text">
                <MoreIcon size={18} />
              </Button>
            </Dropdown>
          </div>
        )}
      </Row>

      <Modal
        title="Spend by category"
        open={showModal}
        okText="Done"
        cancelButtonProps={{
          type: "text",
        }}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <CategoryDonut legend tooltips stats={report.category_stats} />

        <CategoryBreakdownTable stats={report.category_stats} />
      </Modal>
    </div>
  );
}
