import { useEffect } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useMe } from "../Providers";
import { useOops } from "../handleError";

export function useLoadMe() {
  const { api } = useDep();
  const [_, setMe] = useMe();
  const oops = useOops();

  useEffect(() => {
    api.getMe({}).then(setMe).catch(oops);
  }, []);
}
