import QueryString from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useParsedSearch(): Record<any, any> {
  const location = useLocation();
  let search: string = location.search ?? "";
  if (search.startsWith("?")) {
    search = search.substring(1);
  }
  return useMemo((): Record<any, any> => {
    return QueryString.parse(search);
  }, [search]);
}
