/** @jsxImportSource @emotion/react */
import React from "react";
import { LoadingSpinnerIcon } from "./Icons";
import { css, keyframes } from "@emotion/react";
import { IconBaseProps } from "react-icons";

export interface LoadingSpinnerProps extends IconBaseProps {
  className?: string;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const spinningStyle = css`
  animation: ${spin} 1s linear infinite;
`;

export function LoadingSpinner(
  props: React.PropsWithChildren<LoadingSpinnerProps>,
) {
  return <LoadingSpinnerIcon {...props} css={spinningStyle} />;
}
