/** @jsxImportSource @emotion/react */
import { Checkbox, DatePicker, InputNumber, Table, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { mixed, number, object, string } from "yup";
import { Category } from "../lib/Api";
import { CategoryInput } from "./CategoryInput";
import { useDep } from "./DependencyContainerProvider";
import { CheckIcon, ErrorIcon } from "./Icons";
import { RawTransaction } from "./Uploader";
import { useOops } from "./handleError";

export interface ClassifierProps {
  transactions: RawTransaction[];

  // isReady is true when all of the transactions are valid and ready to be
  // saved
  onChange?(transactions: WorkingTransaction[], isReady: boolean): void;
  className?: string;
}

// WorkingTransaction is the data structure that sits in between the "simple" raw
// transactions taken from the user's CSV file and the canonical Transcation record that
// is stored by the API.
export interface WorkingTransaction {
  id: string;
  date: Dayjs | undefined;
  amount: number | undefined;
  description: string | undefined;
  category_id: number | undefined;
  ignore: boolean;
  valid: boolean;
}

const schema = object({
  amount: number().required(),
  date: mixed().required(),
  description: string().required(),
  category_id: number().required(),
});

function isValid(fields: Partial<WorkingTransaction>): boolean {
  if (fields.ignore) {
    return true;
  }
  try {
    schema.validateSync(fields);
    return true;
  } catch (e) {
    return false;
  }
}

export function Classifier(props: React.PropsWithChildren<ClassifierProps>) {
  // const [bankAccountId] = useActiveBankAccount();
  // Set to true before we call the bulk transactions API to save all of the transactions,
  // and set back to false after the API completes.
  const oops = useOops();

  const mapTransactions = (
    transactions: RawTransaction[],
  ): WorkingTransaction[] => {
    return transactions.map((trans): WorkingTransaction => {
      const date = dayjs(trans.date);
      const amount = trans.amount ?? undefined;
      const description = trans.description ?? undefined;
      const categoryId = trans.category_id ?? undefined;

      const fields: Omit<WorkingTransaction, "valid"> = {
        id: uuid(),
        date: date.isValid() ? date : undefined,
        amount,
        category_id: categoryId,
        description: description,
        ignore: false,
      };

      return {
        ...fields,
        valid: isValid(fields),
      } as WorkingTransaction;
    });
  };

  const [transactions, setTransactions] = useState<WorkingTransaction[]>(
    mapTransactions(props.transactions),
  );
  const { api } = useDep();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    api
      .getCategories({})
      .then((resp) => {
        setCategories(resp.categories);
      })
      .catch(oops);
  }, []);

  // Inform the parent components whenever the internal transactions change.
  useEffect(() => {
    if (!props.onChange) {
      return;
    }
    const canContinue = transactions.find((t) => !t.valid) === undefined;
    props.onChange(transactions, canContinue);
  }, [transactions]);

  // Helper function to find a transaction by ID and apply the values to the
  // new the transaction and then update the state for re-rendering
  const updateTransaction = (
    id: string,
    values: Partial<WorkingTransaction>,
  ) => {
    const index = transactions.findIndex((trans) => trans.id === id);
    let trans = transactions[index];
    if (!trans) {
      oops();
      return;
    }
    trans = { ...trans, ...values };
    trans.valid = isValid(trans);
    const transactionsCopy = [...transactions];
    transactionsCopy[index] = trans;
    setTransactions(transactionsCopy);
  };

  return (
    <>
      <Typography.Title level={4} css={{ textAlign: "center" }}>
        Categorize each transaction
      </Typography.Title>
      <Table
        pagination={{
          showSizeChanger: false,
        }}
        title={() => (
          <Typography.Text>
            Found {transactions.length} transactions
          </Typography.Text>
        )}
        size="small"
        dataSource={transactions}
        columns={[
          {
            title: "Amount",
            dataIndex: "amount",
            width: 120,
            render: (_, record) => {
              return (
                <InputNumber
                  variant="borderless"
                  precision={2}
                  disabled={record.ignore}
                  value={record.amount}
                  onChange={(value) => {
                    updateTransaction(record.id, {
                      amount: value ?? undefined,
                    });
                  }}
                />
              );
            },
          },
          {
            title: "Date",
            dataIndex: "date",
            render: (_, record) => {
              return (
                <DatePicker
                  allowClear={false}
                  variant="borderless"
                  disabled={record.ignore}
                  value={record.date}
                  onChange={(value) => {
                    updateTransaction(record.id, { date: value });
                  }}
                />
              );
            },
            width: 140,
          },
          {
            title: "Description",
            dataIndex: "description",
          },
          {
            title: "Category",
            dataIndex: "category_id",
            width: 150,
            render: (_, record) => {
              return (
                <CategoryInput
                  variant={record.ignore ? "borderless" : "filled"}
                  css={{ width: "12rem" }}
                  categories={categories}
                  value={record.category_id}
                  disabled={record.ignore}
                  onChange={(value) => {
                    updateTransaction(record.id, { category_id: value });
                  }}
                />
              );
            },
          },
          {
            title: "Ignore",
            render: (_, transaction) => (
              <div css={{ display: "flex", justifyContent: "center" }}>
                <Checkbox
                  value={!!transaction.ignore}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    updateTransaction(transaction.id, { ignore: checked });
                  }}
                />
              </div>
            ),
            width: 50,
          },
          {
            title: "Status",
            render: (_, transaction) => (
              <div css={{ display: "flex", justifyContent: "center" }}>
                {transaction.valid ? (
                  <Typography.Text type="success">
                    <CheckIcon size={16} />
                  </Typography.Text>
                ) : (
                  <Typography.Text type="danger">
                    <ErrorIcon size={16} />
                  </Typography.Text>
                )}
              </div>
            ),
            width: 50,
          },
        ]}
      />
    </>
  );
}
