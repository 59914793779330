import { SetStateAction, useContext } from "react";

export type SetState<T> = React.Dispatch<SetStateAction<T>>;

export function useRequiredContext<T>(
  context: React.Context<T | undefined>,
): T {
  const value = useContext(context);
  if (value === undefined) {
    throw `context is missing value`;
  }
  return value;
}
