/** @jsxImportSource @emotion/react */
import { Alert, message, Typography } from "antd";
import { isString } from "lodash";
import { useState } from "react";
import { TranslateFn, useTranslate } from "./hooks/useLocaleText";
import { ErrorIcon } from "./Icons";
import { isLocaleKey } from "./Locales";

function getError(translate: TranslateFn, err: any): string {
  if (!err) {
    return translate("common.unknown_error");
  }

  if (isString(err)) {
    return err;
  }

  const errcode = `api.${err?.response?.data?.errcode}`;

  if (isLocaleKey(errcode)) {
    return translate(errcode);
  }

  return translate("common.unknown_error");
}

export interface OopsInline {
  oops: (err: any) => void;
  errorFragment: React.ReactNode;
  clearOops: () => void;
}

export function useOopsInline(): OopsInline {
  const translate = useTranslate();
  const [content, setContent] = useState<string | undefined>();

  const handler = (err: any) => {
    const errString = getError(translate, err);
    setContent(errString);
  };

  let errorFragment: React.ReactNode = null;
  if (content) {
    errorFragment = <Alert message={content} showIcon type="error" />;
  }

  return {
    oops: handler,
    clearOops: () => setContent(undefined),
    errorFragment,
  };
}

export function useOops() {
  const translate = useTranslate();
  return (err?: any) => {
    console.error(err);

    const showMessage = (content: string) => {
      message.open({
        icon: (
          <Typography.Text type="danger" css={{ marginRight: 4 }}>
            <ErrorIcon size={16} css={{ position: "relative", top: 3 }} />
          </Typography.Text>
        ),
        content: <Typography.Text>{content}</Typography.Text>,
      });
    };
    const content = getError(translate, err);
    showMessage(content);
  };
}
