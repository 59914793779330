/** @jsxImportSource @emotion/react */
import { Checkbox, Form, FormProps, Input, Modal, Typography } from "antd";
import React from "react";
import { BankAccount } from "../lib/Api";
import { useDep } from "./DependencyContainerProvider";
import { useBankAccounts } from "./Providers";
import { useOops } from "./handleError";

export interface NewBankAccountModalProps {
  className?: string;
  open: boolean;
  onDone(bankAccount?: BankAccount): any;
}

interface FormValues {
  name: string;
  isPublic: boolean;
}

export function NewBankAccountModal(
  props: React.PropsWithChildren<NewBankAccountModalProps>,
) {
  const { api } = useDep();
  const [form] = Form.useForm();
  const [bankAccounts, setBankAccounts] = useBankAccounts();
  const oops = useOops();

  const onFinish: FormProps<FormValues>["onFinish"] = (values) => {
    const { name, isPublic } = values;
    api
      .createBankAccount({ name, is_public: isPublic })
      .then((resp) => {
        setBankAccounts([...bankAccounts, resp.bank_account]);
        props.onDone(resp.bank_account);
      })
      .catch(oops);
  };

  return (
    <Modal
      cancelButtonProps={{ type: "text" }}
      title="Add a bank account"
      open={props.open}
      onCancel={() => props.onDone()}
      afterClose={form.resetFields}
      onOk={form.submit}
    >
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        initialValues={{ isPublic: true }}
      >
        <Form.Item
          name="name"
          label="Give this account a name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Ex. Savings or Joint Checkings" />
        </Form.Item>

        <Form.Item
          name="isPublic"
          tooltip="Make this bank account only visible to you. If you add other people to your account they won't be able to view it."
          label="Visibility"
        >
          <Checkbox>
            <Typography.Text>Private account</Typography.Text>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
