import { useEffect } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useUsers } from "../Providers";
import { useOops } from "../handleError";

export function useLoadUsers() {
  const { api } = useDep();
  const [_, setUsers] = useUsers();
  const oops = useOops();

  useEffect(() => {
    api
      .getUsers({})
      .then((resp) => setUsers(resp.users))
      .catch(oops);
  }, []);
}
