/** @jsxImportSource @emotion/react */
import { ConfigProvider, theme } from "antd";
import React from "react";
import { AppColor } from "./design/colors";
import { appFontFamily } from "./design/fonts";
import { useSettings } from "./SettingsProvider";
import { useToken } from "./TokenProvider";

export interface AppThemeProviderProps {
  className?: string;
}

export function AppThemeProvider(
  props: React.PropsWithChildren<AppThemeProviderProps>,
) {
  const token = useToken();
  const { settings } = useSettings();

  let algo = theme.defaultAlgorithm;
  if (token && settings.darkMode) {
    algo = theme.darkAlgorithm;
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: algo,
        token: {
          fontFamily: appFontFamily,
          colorPrimary: AppColor.Green8,
          colorLink: settings.darkMode ? AppColor.Green8 : AppColor.Green14,
          fontWeightStrong: 700,
        },
        components: {
          Button: {
            fontWeight: 500,
          },
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
}
