import { useCallback } from "react";
import { useIntl } from "react-intl";
import { LocaleKey } from "../Locales";

export interface TranslateFn {
  (key: LocaleKey): string;
}

export function useTranslate() {
  const intl = useIntl();

  return useCallback(
    (id: LocaleKey) => {
      return intl.formatMessage({ id });
    },
    [intl],
  );
}
