/** @jsxImportSource @emotion/react */
import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import { ApplicationLoaders } from "./ApplicationLoaders";
import { AppNavbar } from "./AppNavbar";
import { AuthGuard } from "./AuthGuard";
import {
  BankAccountsProvider,
  CategoriesProvider,
  MeProvider,
  UpgradeUrlProvider,
  UsersProvider,
} from "./Providers";

const { Content } = Layout;

export interface ApplicationLayoutProps {
  className?: string;
}

export function ApplicationLayout(
  props: React.PropsWithChildren<ApplicationLayoutProps>,
) {
  return (
    <AuthGuard>
      <UsersProvider>
        <UpgradeUrlProvider>
          <MeProvider>
            <CategoriesProvider>
              <BankAccountsProvider>
                <ApplicationLoaders>
                  <Layout css={{ height: "100vh" }}>
                    <AppNavbar />
                    <Content>
                      <Outlet />
                    </Content>
                  </Layout>
                </ApplicationLoaders>
              </BankAccountsProvider>
            </CategoriesProvider>
          </MeProvider>
        </UpgradeUrlProvider>
      </UsersProvider>
    </AuthGuard>
  );
}
