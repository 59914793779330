/** @jsxImportSource @emotion/react */
import { Button, Divider, Form, FormProps, Input, Typography } from "antd";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppColor } from "./design/colors";
import { useOopsInline } from "./handleError";
import { useToken } from "./TokenProvider";
import useLogin from "./useLogin";

export interface LoginPageProps {
  className?: string;
}

interface FormValues {
  email: string;
  password: string;
}

export function LoginPage(props: React.PropsWithChildren<LoginPageProps>) {
  const navigate = useNavigate();
  const login = useLogin();
  const token = useToken();

  const { oops, errorFragment } = useOopsInline();

  if (!!token) {
    return <Navigate to="/overview" />;
  }

  const onFinish: FormProps<FormValues>["onFinish"] = (values) => {
    const { email, password } = values;
    login(email, password)
      .then((resp) => {
        navigate("/overview");
      })
      .catch(oops);
  };

  return (
    <div css={{ display: "flex", justifyContent: "center" }}>
      <div css={{ maxWidth: "20rem", width: "100%", paddingTop: "2rem" }}>
        <div css={{ padding: "1rem", textAlign: "center" }}>
          <Typography.Title level={3}>Sign into MunkMunk</Typography.Title>
        </div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          colon={false}
          requiredMark={false}
        >
          {errorFragment}
          <Form.Item
            help=""
            name="email"
            label="Email"
            rules={[{ required: true }]}
          >
            <Input autoFocus placeholder="Email" />
          </Form.Item>

          <Form.Item
            help=""
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Link to="/password-reset" css={{ fontWeight: 500 }}>
              <Typography.Text style={{ color: AppColor.Green8 }}>
                Forgot your password?
              </Typography.Text>
            </Link>
          </Form.Item>

          <Button block type="primary" htmlType="submit">
            Login
          </Button>
        </Form>

        <Divider />

        <div css={{ textAlign: "center" }}>
          <Typography.Text type="secondary">
            Don't have an account?{" "}
            <strong>
              <Link style={{ color: AppColor.Green8 }} to="/join">
                Create one for free
              </Link>
            </strong>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}
