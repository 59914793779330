import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { SetState } from "./helpers";

// token can be one of the following types:
// - string (logged in)
// - null (logged out)
// - undefined - Provider is missing
type TokenType = string | null;

const GetContext = createContext<TokenType | undefined>(undefined);

const SetContext = createContext<
  React.Dispatch<SetStateAction<TokenType>> | undefined
>(undefined);

export interface TokenProviderProps extends PropsWithChildren {
  defaultValue: TokenType;
}

export function TokenProvider(props: TokenProviderProps) {
  const [token, setToken] = useState<string | null>(props.defaultValue);

  return (
    <GetContext.Provider value={token}>
      <SetContext.Provider value={setToken}>
        {props.children}
      </SetContext.Provider>
    </GetContext.Provider>
  );
}

export const TOKEN_PROVIDER_MISSING = new Error("<TokenProvider/> is missing");

export function useToken(): TokenType {
  const token = useContext(GetContext);
  if (token === undefined) {
    throw TOKEN_PROVIDER_MISSING;
  }
  return token;
}

export function useTokenSetter(): SetState<TokenType> {
  const setter = useContext(SetContext);
  if (setter === undefined) {
    throw TOKEN_PROVIDER_MISSING;
  }
  return setter;
}
