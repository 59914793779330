/** @jsxImportSource @emotion/react */
import {
  Button,
  Card,
  Form,
  FormProps,
  Input,
  message,
  Typography,
} from "antd";
import React from "react";
import { useDep } from "./DependencyContainerProvider";
import { useOops } from "./handleError";

export interface PasswordResetPageProps {
  className?: string;
}

interface FormValues {
  email: string;
}

export function PasswordResetPage(
  props: React.PropsWithChildren<PasswordResetPageProps>,
) {
  const [form] = Form.useForm();
  const { api } = useDep();
  const oops = useOops();

  const onFinish: FormProps<FormValues>["onFinish"] = (values) => {
    const { email } = values;
    api
      .createResetToken({ email })
      .then(() => {
        message.success("Password reset email sent");
      })
      .catch(oops);
  };

  return (
    <div
      className={props.className}
      css={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}
    >
      <Card
        css={{
          maxWidth: "24rem",
          width: "100%",
          height: "24rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div css={{ maxWidth: "24rem", width: "100%" }}>
          <div css={{ textAlign: "center" }}>
            <Typography.Title level={3}>Reset your password</Typography.Title>
          </div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={onFinish}
          >
            <Form.Item
              rules={[{ required: true, type: "email" }]}
              name="email"
              help=""
              label="Enter your email"
            >
              <Input variant="filled" />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Card>
    </div>
  );
}
