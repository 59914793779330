/** @jsxImportSource @emotion/react */
import React from "react";
import { FormattedMessage } from "react-intl";
import { LocaleKey } from "./Locales";

export interface LocaleTextProps {
  id: LocaleKey;
}

export function LocaleText(props: React.PropsWithChildren<LocaleTextProps>) {
  return <FormattedMessage id={props.id} />;
}
