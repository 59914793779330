/** @jsxImportSource @emotion/react */
import { List } from "antd";
import React from "react";
import { MonthlyReport } from "../lib/Api";
import { MonthlyReportPreview } from "./MonthlyReportPreview";

export interface ReportListProps {
  className?: string;
  reports: MonthlyReport[];
}

export function ReportList(props: React.PropsWithChildren<ReportListProps>) {
  return (
    <List>
      {props.reports.map((report) => (
        <List.Item>
          <MonthlyReportPreview
            css={{ flex: 1, width: "100%" }}
            report={report}
          />
        </List.Item>
      ))}
    </List>
  );
}
