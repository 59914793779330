/** @jsxImportSource @emotion/react */
import React from "react";
import image from "../images/munkmunk.png";

export interface MunkLogoProps {
  className?: string;
  size: number;
}

export function MunkLogo(props: React.PropsWithChildren<MunkLogoProps>) {
  return (
    <div
      className={props.className}
      css={{
        borderRadius: props.size,
        width: props.size,
        height: props.size,
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "115%",
      }}
    />
  );
}
