import axios, { AxiosInstance } from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";

export interface BaseAttributes {
  id: number;
  created_at: number;
  updated_at: number;
}

export interface Account extends BaseAttributes {
  is_member: boolean;
  renew_date: number;
  auto_renew: boolean;
}

export interface User extends BaseAttributes {
  email: string;
}

export interface CreateAccountRequest {
  email: string;
  password: string;
}

export interface CreateAccountResponse {
  account: Account;
}

export interface CreateTokenRequest {
  email: string;
  password: string;
}

export interface CreateTokenResponse {
  token: string;
}

export interface CreateTransactionImportRequest {}

export interface CreateTransactionImportResponse {
  upload: {
    url: string;
    fields: Record<string, string>;
    bucket: string;
    key: string;
  };
  download: {
    url: string;
  };
}

export interface ProcessTransactionImportRequest {
  bucket: string;
  key: string;
}

export interface ProcessTransactionImportResponse {}

export interface Category extends BaseAttributes {
  name: string;
  status: "expense" | "income";
}

export interface GetCategoriesRequest {}

export interface GetCategoriesResponse {
  categories: Category[];
}

export interface BulkTransactionItem {
  amount_in_cents: number;
  date: string;
  category_id: number;
  description: string;
}

export interface CreateTransactionsBulkRequest {
  transactions: BulkTransactionItem[];
  bank_account_id: number;
}

export interface CreateTransactionsBulkResponse {
  dates: string[];
}

export interface CreateMonthlyReportRequest {
  date: string;
  bank_account_id: number;
}

export interface CreateMonthlyReportResponse {
  monthly_report: MonthlyReport;
}

export interface CategoryStats {
  totals: Record<number, number>;
  metadata: Record<number, string>;
}

export interface MonthlyReport extends BaseAttributes {
  date: string;
  amount_in_cents: number;
  transaction_count: number;
  category_stats: CategoryStats;
}

export interface GetMonthlyReportsRequest {
  bank_account_id: number;
  dates?: string[];
}

export interface GetMonthlyReportsResponse {
  monthly_reports: MonthlyReport[];
}

export interface BankAccount extends BaseAttributes {
  owner_id: number;
  is_public: boolean;
  name: string;
}

export interface GetBankAccountsRequest {}

export interface GetBankAccountsResponse {
  bank_accounts: BankAccount[];
}

export interface CreateBankAccountRequest {
  name: string;
  is_public: boolean;
}

export interface CreateBankAccountResponse {
  bank_account: BankAccount;
}

export interface GetMeRequest {}

export interface GetMeResponse {
  account: Account;
  user: User;
}

export interface GetUsersRequest {}

export interface GetUsersResponse {
  users: User[];
}

export interface UpdateAccountRequest {
  auto_renew: boolean;
}

export interface UpdateAccountResponse {
  account: Account;
}

export interface CreateUserReq {
  email: string;
}

export interface CreateUserResponse {
  user: User;
}

export interface CreateCategoryReq {
  name: string;
}

export interface CreateCategoryResponse {
  category: Category;
}

export interface DeleteReportResponse {}

export interface DeleteCategoryResponse {}

export interface UpdateCategoryRequest {
  status: "expense" | "income";
}
export interface UpdateCategoryResponse {
  category: Category;
}

export interface DestroyBankAccountResponse {}

export interface CreateResetTokenRequest {
  email: string;
}

export interface CreateResetTokenResponse {}

export interface CreateCheckoutSessionResponse {
  // If the account is missing stripe_customer do to
  // being a new account (or an error) then url will
  // not be returned
  url?: string;
}

export class Api {
  constructor(private readonly network: AxiosInstance) {}

  static networkFromToken(token: string | null): AxiosInstance {
    const headers: Record<string, string> = {};

    if (token) {
      headers["Authorization"] = token;
    }
    return axios.create({
      headers,
      baseURL: process.env.REACT_APP_API_HOST,
    });
  }

  withRetry(config: IAxiosRetryConfig) {
    const network = axios.create(this.network.defaults);
    axiosRetry(network, config);
    return new Api(network);
  }

  createAccount(req: CreateAccountRequest): Promise<CreateAccountResponse> {
    return this.network.post(`/api/accounts`, req).then((resp) => resp.data);
  }

  createToken(req: CreateTokenRequest): Promise<CreateTokenResponse> {
    return this.network.post("/api/tokens", req).then((resp) => resp.data);
  }

  createTransactionImport(
    req: CreateTransactionImportRequest,
  ): Promise<CreateTransactionImportResponse> {
    return this.network
      .post("/api/transaction_imports", req)
      .then((resp) => resp.data);
  }

  processTransactionImport(
    req: ProcessTransactionImportRequest,
  ): Promise<ProcessTransactionImportResponse> {
    return this.network
      .post("/api/transaction_imports/start_job", req)
      .then((resp) => resp.data);
  }

  getCategories(req: GetCategoriesRequest): Promise<GetCategoriesResponse> {
    return this.network.get(`/api/categories`, req).then((resp) => resp.data);
  }

  updateCategory(
    id: number,
    req: UpdateCategoryRequest,
  ): Promise<UpdateCategoryResponse> {
    return this.network
      .put(`/api/categories/${id}`, req)
      .then((resp) => resp.data);
  }

  createTransactionsBulk(
    req: CreateTransactionsBulkRequest,
  ): Promise<CreateTransactionsBulkResponse> {
    return this.network
      .post(`/api/transactions/bulk`, req)
      .then((resp) => resp.data);
  }

  createMonthlyReport(
    req: CreateMonthlyReportRequest,
  ): Promise<CreateMonthlyReportResponse> {
    return this.network
      .post(`/api/monthly_reports`, req)
      .then((resp) => resp.data);
  }

  getMonthlyReports(
    params: GetMonthlyReportsRequest,
  ): Promise<GetMonthlyReportsResponse> {
    return this.network
      .get(`/api/monthly_reports`, { params })
      .then((resp) => resp.data);
  }

  getBankAccounts(
    params: GetBankAccountsRequest,
  ): Promise<GetBankAccountsResponse> {
    return this.network
      .get(`/api/bank_accounts`, { params })
      .then((resp) => resp.data);
  }

  createBankAccount(
    req: CreateBankAccountRequest,
  ): Promise<CreateBankAccountResponse> {
    return this.network
      .post(`/api/bank_accounts`, req)
      .then((resp) => resp.data);
  }

  getMe(params: GetMeRequest): Promise<GetMeResponse> {
    return this.network.get(`/api/me`, { params }).then((resp) => resp.data);
  }

  createCheckoutSessionUrl(): Promise<CreateCheckoutSessionResponse> {
    return this.network
      .post(`/api/checkout_sessions`, {})
      .then((resp) => resp.data);
  }

  getUsers(params: GetUsersRequest): Promise<GetUsersResponse> {
    return this.network.get(`/api/users`, { params }).then((resp) => resp.data);
  }

  updateAccount(
    id: number,
    req: UpdateAccountRequest,
  ): Promise<UpdateAccountResponse> {
    return this.network
      .put(`/api/accounts/${id}`, req)
      .then((resp) => resp.data);
  }

  createUser(req: CreateUserReq): Promise<CreateUserResponse> {
    return this.network.post(`/api/users`, req).then((resp) => resp.data);
  }

  createCategory(req: CreateCategoryReq): Promise<CreateCategoryResponse> {
    return this.network.post(`/api/categories`, req).then((resp) => resp.data);
  }

  deleteReports(id: number): Promise<DeleteReportResponse> {
    return this.network
      .delete(`/api/monthly_reports/${id}`)
      .then((resp) => resp.data);
  }

  destroyCategory(id: number): Promise<DeleteCategoryResponse> {
    return this.network
      .delete(`/api/categories/${id}`)
      .then((resp) => resp.data);
  }

  destroyBankAccount(id: number): Promise<DestroyBankAccountResponse> {
    return this.network
      .delete(`/api/bank_accounts/${id}`)
      .then((resp) => resp.data);
  }

  createResetToken(
    req: CreateResetTokenRequest,
  ): Promise<CreateResetTokenResponse> {
    return this.network
      .post(`/api/reset_tokens`, req)
      .then((resp) => resp.data);
  }
}
