/** @jsxImportSource @emotion/react */
import { Account, BankAccount, Category, User } from "../lib/Api";
import { createProvider } from "./createProvider";

export const [ActiveBankAccountProvider, useActiveBankAccount] = createProvider<
  number | undefined
>(undefined);

export const [BankAccountsProvider, useBankAccounts] = createProvider<
  BankAccount[]
>([]);

export const [CategoriesProvider, useCategories] = createProvider<Category[]>(
  [],
);

export const [MeProvider, useMe] = createProvider<
  { account: Account; user: User } | undefined
>(undefined);

export const [UpgradeUrlProvider, useUpgradeUrl] = createProvider<string>("");

export const [UsersProvider, useUsers] = createProvider<User[]>([]);

export const [UserLocaleProvider, useUserLocale] = createProvider<
  string | undefined
>(undefined);
