/** @jsxImportSource @emotion/react */
import { Form, FormProps, Input, message, Modal } from "antd";
import React from "react";
import { useDep } from "./DependencyContainerProvider";
import { useUsers } from "./Providers";
import { useOops } from "./handleError";
import { useTranslate } from "./hooks/useLocaleText";

export interface AddPersonModalProps {
  className?: string;
  open: boolean;
  onCancel(): void;
  onDone(): void;
}

interface FormValues {
  email: string;
}

export function AddPersonModal(
  props: React.PropsWithChildren<AddPersonModalProps>,
) {
  const [form] = Form.useForm();
  const { api } = useDep();
  const [users, setUsers] = useUsers();
  const oops = useOops();
  const translate = useTranslate();

  const onFinish: FormProps<FormValues>["onFinish"] = (values) => {
    const { email } = values;
    api
      .createUser({ email })
      .then((resp) => {
        setUsers([...users, resp.user]);
        message.success("User successfully added");
        props.onDone();
      })
      .catch(oops);
  };

  return (
    <Modal
      afterClose={() => form.resetFields()}
      open={props.open}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        colon={false}
        requiredMark={false}
      >
        <Form.Item
          name={"email"}
          help=""
          label="Enter an email to add to this account"
          rules={[
            {
              required: true,
              message: translate("add_person_modal.email_require"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
