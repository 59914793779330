/** @jsxImportSource @emotion/react */
import { Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDep } from "./DependencyContainerProvider";
import { LoadingSpinner } from "./LoadingSpinner";
import { useMe } from "./Providers";

export interface CheckoutSuccessPageProps {
  className?: string;
}

export function CheckoutSuccessPage(
  props: React.PropsWithChildren<CheckoutSuccessPageProps>,
) {
  const intRef = useRef<NodeJS.Timer | undefined>(undefined);
  const { api } = useDep();
  const [loading, setLoading] = useState<boolean>(false);
  const [me, setMe] = useMe();

  const getIsMember = () => {
    return api.getMe({}).then((resp) => {
      if (resp.account.is_member) {
        clearInterval(intRef.current);
        setMe(resp);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    intRef.current = setInterval(() => {
      getIsMember();
    }, 3000);

    return () => {
      console.log(`unmonting?`);
      clearInterval(intRef.current);
    };
  }, []);

  if (loading) {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          justifyContent: "center",
          padding: "2rem 0",
        }}
      >
        <LoadingSpinner size={32} />
        <Typography.Text type="secondary">Loading membership</Typography.Text>
      </div>
    );
  }

  if (me?.account.is_member) {
    return <Typography.Title>You're a member!</Typography.Title>;
  }

  return <Typography.Title>Couldnt fetch membership</Typography.Title>;
}
