import { useEffect } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useUpgradeUrl } from "../Providers";
import { useOops } from "../handleError";

export function useLoadUpgradeUrl() {
  const { api } = useDep();
  const [_, setUpgradeUrl] = useUpgradeUrl();
  const oops = useOops();

  useEffect(() => {
    api
      .createCheckoutSessionUrl()
      .then((resp) => {
        if (resp.url) {
          setUpgradeUrl(resp.url);
        }
      })
      .catch(oops);
  }, []);
}
