/** @jsxImportSource @emotion/react */
import { Form, FormProps, Input, Modal } from "antd";
import React from "react";
import { useDep } from "./DependencyContainerProvider";
import { useCategories } from "./Providers";
import { useOops } from "./handleError";

export interface AddCategoryModalProps {
  className?: string;
  open: boolean;
  onDone(): any;
  onCancel(): any;
}

export interface FormValues {
  name: string;
}

export function AddCategoryModal(
  props: React.PropsWithChildren<AddCategoryModalProps>,
) {
  const [form] = Form.useForm();
  const { api } = useDep();
  const [categories, setCategories] = useCategories();
  const oops = useOops();

  const onFinish: FormProps<FormValues>["onFinish"] = (values) => {
    const { name } = values;
    api
      .createCategory({ name })
      .then((resp) => {
        setCategories([...categories, resp.category]);
        props.onDone();
      })
      .catch(oops);
  };

  return (
    <Modal
      onOk={form.submit}
      afterClose={() => form.resetFields()}
      onCancel={props.onCancel}
      open={props.open}
      title="Add category"
      cancelButtonProps={{ type: "text" }}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        colon={false}
        requiredMark={false}
        form={form}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
