/** @jsxImportSource @emotion/react */
import { Button, Divider, Form, FormProps, Input, Typography } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDep } from "./DependencyContainerProvider";
import { AppColor } from "./design/colors";
import { useOopsInline } from "./handleError";
import { useTranslate } from "./hooks/useLocaleText";
import { LocaleText } from "./LocaleText";
import useLogin from "./useLogin";

export interface JoinPageProps {
  className?: string;
}

export function JoinPage(props: React.PropsWithChildren<JoinPageProps>) {
  const { api } = useDep();
  const { oops, errorFragment } = useOopsInline();
  const login = useLogin();
  const navigate = useNavigate();
  const translate = useTranslate();

  const onFinish: FormProps["onFinish"] = (values) => {
    const { email, password } = values;
    api
      .createAccount({ email, password })
      .then((resp) => {
        return login(email, password).then((resp) => {
          navigate("/welcome");
        });
      })
      .catch(oops);
  };

  return (
    <div css={{ display: "flex", justifyContent: "center" }}>
      <div css={{ maxWidth: "20rem", width: "100%", paddingTop: "2rem" }}>
        <div css={{ textAlign: "center" }}>
          <Typography.Title style={{ textAlign: "center" }} level={3}>
            <LocaleText id="join_page.sign_up_for_munkmunk" />
          </Typography.Title>

          <Typography.Paragraph type="secondary">
            <LocaleText id="join_page.create_free_accoint" />
          </Typography.Paragraph>
        </div>

        <Form
          onFinish={onFinish}
          layout="vertical"
          colon={false}
          requiredMark={false}
        >
          {errorFragment}
          <Form.Item
            help=""
            name="email"
            label={translate("common.email")}
            rules={[
              { required: true, message: translate("api.err_email_required") },
            ]}
          >
            <Input autoFocus placeholder={translate("common.email")} />
          </Form.Item>

          <Form.Item
            help=""
            name="password"
            label={translate("common.password")}
            rules={[
              {
                required: true,
                message: translate("api.err_password_required"),
              },
              { min: 6, message: translate("api.err_password_length") },
            ]}
          >
            <Input type="password" placeholder={translate("common.password")} />
          </Form.Item>

          <Button type="primary" block htmlType="submit">
            <LocaleText id="join_page.create_account_btn" />
          </Button>
        </Form>
        <Divider />

        <div css={{ textAlign: "center" }}>
          <Typography.Text type="secondary" css={{ textAlign: "center" }}>
            <LocaleText id="join_page.already_have_account" />
            <br />
            <strong>
              <Link style={{ color: AppColor.Green8 }} to="/login">
                <LocaleText id="join_page.sign_in" />
              </Link>
            </strong>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}
