import { Api } from "../lib/Api";

/**
 * DependencyContainer is a manifest of all the business & data dependencies
 * needed to power the UI.
 */
export class DependencyContainer {
  public readonly api: Api;

  constructor(public readonly token: string | null) {
    const networkClient = Api.networkFromToken(token);
    this.api = new Api(networkClient);
  }
}
