/** @jsxImportSource @emotion/react */
import { Button } from "antd";
import useToken from "antd/es/theme/useToken";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { useMe, useUpgradeUrl } from "./Providers";

export interface UpgradeBannerProps {
  className?: string;
}

export function UpgradeBanner(
  props: React.PropsWithChildren<UpgradeBannerProps>,
) {
  const [_, theme] = useToken();
  const [me] = useMe();
  const [upgradeUrl] = useUpgradeUrl();

  if (!me || me.account.is_member || !upgradeUrl) {
    return null;
  }

  return (
    <Fade>
      <Button
        type="primary"
        onClick={() => {
          if (upgradeUrl) {
            window.location.href = upgradeUrl;
          }
        }}
      >
        Upgrade for $99
      </Button>
    </Fade>
  );
}
