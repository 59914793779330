/** @jsxImportSource @emotion/react */
import { Select, SelectProps } from "antd";
import React from "react";
import { BankAccount } from "../lib/Api";

export interface BankAccountDropdownProps extends SelectProps {
  className?: string;
  bankAccounts: BankAccount[];
}

export function BankAccountDropdown(
  props: React.PropsWithChildren<BankAccountDropdownProps>,
) {
  const { bankAccounts, ...otherProps } = props;
  const options: SelectProps["options"] = bankAccounts.map((account) => {
    return {
      label: account.name,
      value: account.id,
      key: account.id,
    };
  });
  return <Select {...otherProps} options={options} />;
}
