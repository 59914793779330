const KEY = "token";

/**
 * Clears out any old tokens and stores the new user token
 * @param token
 * @param remember
 * @returns
 */
export function storeToken(token: string, remember?: boolean) {
  clearToken();
  const storage: Storage = remember ? localStorage : sessionStorage;
  storage.setItem(KEY, token);
  return;
}

export function getToken(): string | null {
  return sessionStorage.getItem(KEY) ?? localStorage.getItem(KEY);
}

/**
 * Clears token from both Storages
 */
export function clearToken(): void {
  sessionStorage.removeItem(KEY);
  localStorage.removeItem(KEY);
}
