/** @jsxImportSource @emotion/react */
import { Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MonthlyReport } from "../lib/Api";
import { useDep } from "./DependencyContainerProvider";
import { useOops } from "./handleError";
import { useActiveBankAccount } from "./Providers";

export interface ReportsPreviewProps {
  className?: string;
  dates: string[];
}

export function ReportsPreview(
  props: React.PropsWithChildren<ReportsPreviewProps>,
) {
  const [bankAccountId] = useActiveBankAccount();
  const navigate = useNavigate();
  const [reports, setReports] = useState<MonthlyReport[]>([]);
  const { api } = useDep();
  const oops = useOops();

  useEffect(() => {
    if (!bankAccountId) {
      console.error(
        `could not get the active  bank account ID to create the monthly report with`,
      );
      oops();
      return;
    }
    Promise.all(
      props.dates.map((date) =>
        api
          .createMonthlyReport({ date, bank_account_id: bankAccountId })
          .then((resp) => resp.monthly_report),
      ),
    ).then((reports) => {
      setReports(reports);

      const navigateToOverview = () => {
        navigate(`/overview?bankAccountId=${bankAccountId}`);
      };

      Modal.confirm({
        maskClosable: true,
        cancelText: "Done",
        icon: null,
        closable: false,
        title: <>🎉 Nice!</>,
        content: <Typography.Text>Your reports are ready</Typography.Text>,
        okText: "Go to reports",
        cancelButtonProps: {
          type: "text",
        },
        onCancel: navigateToOverview,
        onClose: navigateToOverview,
        onOk: navigateToOverview,
      });
    });
  }, []);

  return null;
}
