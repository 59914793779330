import { AiOutlineLoading } from "react-icons/ai";
import { BsBank, BsFiletypeCsv } from "react-icons/bs";
import { FaChartBar, FaChartPie, FaRegCheckCircle } from "react-icons/fa";
import { FiMoreVertical, FiUser } from "react-icons/fi";
import { IoCloudUploadOutline, IoHelpCircle } from "react-icons/io5";
import { LuAlertCircle } from "react-icons/lu";
import { MdErrorOutline, MdOutlineSettings } from "react-icons/md";

export const UploadIcon = IoCloudUploadOutline;
export const LoadingSpinnerIcon = AiOutlineLoading;
export const AlertIcon = LuAlertCircle;
export const HelpIcon = IoHelpCircle;
export const CheckIcon = FaRegCheckCircle;
export const ErrorIcon = MdErrorOutline;
export const PieChartIcon = FaChartPie;
export const ChartIcon = FaChartBar;
export const UserIcon = FiUser;
export const SettingsIcon = MdOutlineSettings;
export const BankIcon = BsBank;
export const CsvIcon = BsFiletypeCsv;
export const MoreIcon = FiMoreVertical;
