/** @jsxImportSource @emotion/react */
import { Card } from "antd";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { Onboarding } from "./Onboarding";

export interface WelcomePageProps {
  className?: string;
}

export function WelcomePage(props: React.PropsWithChildren<WelcomePageProps>) {
  return (
    <Fade>
      <Card>
        <div css={{ display: "flex", justifyContent: "center" }}>
          <div css={{ width: "100%", maxWidth: "60rem" }}>
            <Onboarding />
          </div>
        </div>
      </Card>
    </Fade>
  );
}
