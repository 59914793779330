/** @jsxImportSource @emotion/react */
import { Button, Typography } from "antd";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import useLogin from "./useLogin";
import { useToken } from "./TokenProvider";

export interface HomePageProps {
  className?: string;
}

export function HomePage(props: React.PropsWithChildren<HomePageProps>) {
  const token = useToken();
  if (token) {
    return <Navigate to="/overview" />;
  }
  return <Navigate to="/login" />;
}
