import { useMemo } from "react";
import { array, number, object, string } from "yup";

const urlFilterSchema = object({
  dates: array().optional().of(string().required()),
  bankAccountId: number().optional(),
});

export function useReportUrlFilters(
  params: Record<any, any>,
): (typeof urlFilterSchema)["__outputType"] {
  return useMemo(() => {
    try {
      const filters = urlFilterSchema.validateSync(params, {
        strict: false,
        stripUnknown: true,
      });
      return filters;
    } catch (e) {
      console.error(e);
      return {};
    }
  }, [params]);
}
