/** @jsxImportSource @emotion/react */
import React from "react";
import { Navigate } from "react-router-dom";
import { useToken } from "./TokenProvider";

export interface AuthGuardProps {
  className?: string;
}

export function AuthGuard(props: React.PropsWithChildren<AuthGuardProps>) {
  const token = useToken();
  if (!token) {
    return <Navigate to={"/login"} />;
  }
  return <>{props.children}</>;
}
