/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import { Button, Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import React, { useRef, useState } from "react";
import { IconBaseProps } from "react-icons";
import { useNavigate } from "react-router-dom";
import { AppColor } from "./design/colors";
import { OnboardingCard } from "./OnboardingCard";

export interface OnboardingProps {
  className?: string;
}

export function Onboarding(props: React.PropsWithChildren<OnboardingProps>) {
  const contentStyle: Interpolation<Theme> = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const iconProps: IconBaseProps = {
    size: 64,
    color: AppColor.Green7,
    style: {
      marginBottom: "1rem",
    },
  };

  const navigate = useNavigate();
  const carousel = useRef<CarouselRef>(null);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const maxIndex = 3;
  const lastIndex = carouselIndex === maxIndex;

  return (
    <>
      <Carousel
        beforeChange={(_, next) => setCarouselIndex(next)}
        ref={carousel}
        css={{ background: AppColor.Green4, borderRadius: 12 }}
      >
        <OnboardingCard
          icon={null}
          title="Welcome"
          text={<span>Use this quick start guide to start using MunkMunk</span>}
        />
        <OnboardingCard
          icon={null}
          title="Budgeting starts with your bank account"
          text={
            <span>
              A <strong>Default</strong> bank account has been created for you.
            </span>
          }
        />

        <OnboardingCard
          icon={null}
          title="Upload your bank activity into MunkMunk"
          text="Export and upload CSV files of your bank activity every month"
        />

        <OnboardingCard
          icon={null}
          title="Review your monthly reports"
          text="See how much you've saved or spent with breakdowns & insights"
        />
      </Carousel>

      <div
        css={{
          display: "flex",
          justifyContent: "center",
          padding: "3rem 0",
        }}
      >
        <div>
          <Button
            disabled={carouselIndex === 0}
            size="large"
            css={{ borderRadius: "1000px" }}
            type="text"
            onClick={() => {
              carousel.current?.prev();
            }}
          >
            ←
          </Button>

          <Button
            size="large"
            css={{ borderRadius: "1000px" }}
            type="primary"
            onClick={() => {
              if (lastIndex) {
                navigate("/overview");
              } else {
                carousel.current?.next();
              }
            }}
          >
            {lastIndex ? "Get Started" : "→"}
          </Button>
        </div>
      </div>
    </>
  );
}
