/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import { Layout, Typography } from "antd";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { MunkLogo } from "./MunkLogo";
import { AppColor } from "./design/colors";

export interface PublicLayoutProps {
  className?: string;
}

export function PublicLayout(
  props: React.PropsWithChildren<PublicLayoutProps>,
) {
  const linkStyle: Interpolation<Theme> = {
    fontWeight: 500,
    textDecoration: "none",
    borderBottom: "2px solid transparent",
    ":hover": {
      borderBottom: "2px solid #000",
    },
  };

  return (
    <Layout css={{ height: "100vh" }}>
      <div
        css={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          padding: ".25rem 1rem",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <div
          css={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <MunkLogo size={64} />
          <Typography.Title
            style={{
              marginBottom: 0,
              fontWeight: 700,
              color: AppColor.Green3,
            }}
            level={4}
          >
            <Link to="/">MunkMunk</Link>
          </Typography.Title>
        </div>

        <div css={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Link to="/login" css={linkStyle}>
            <Typography.Text>Login</Typography.Text>
          </Link>

          <Link to="/join" css={linkStyle}>
            <Typography.Text>Sign up</Typography.Text>
          </Link>
        </div>
      </div>

      <Layout.Content css={{ background: "#fff" }}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
}
