import React, { createContext, ReactNode, useContext, useState } from "react";

type ProviderFactoryResult<T> = [
  React.FC<{ children: ReactNode }>,
  () => [T, React.Dispatch<React.SetStateAction<T>>],
];

export function createProvider<T>(defaultValue: T): ProviderFactoryResult<T> {
  const Context = createContext<
    [T, React.Dispatch<React.SetStateAction<T>>] | undefined
  >(undefined);

  const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const state = useState<T>(defaultValue);
    return <Context.Provider value={state}>{children}</Context.Provider>;
  };

  const useProvider = (): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const context = useContext(Context);
    if (!context) {
      throw new Error("useProvider must be used within its Provider");
    }
    return context;
  };

  return [Provider, useProvider];
}
