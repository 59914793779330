import { useEffect } from "react";
import { useDep } from "../DependencyContainerProvider";
import { useOops } from "../handleError";
import { useBankAccounts } from "../Providers";

// This loader has a dependency on the BankAccountsProvider. It calls the API
// to fetch all bank accounts and loads up the array.
export function useLoadBankAccounts() {
  const { api } = useDep();
  const [_, setBankAccounts] = useBankAccounts();
  const oops = useOops();

  useEffect(() => {
    api
      .getBankAccounts({})
      .then((resp) => {
        setBankAccounts(resp.bank_accounts);
      })
      .catch(oops);
  }, []);
}
