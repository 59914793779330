/** @jsxImportSource @emotion/react */
import { Button, Card, Modal, Steps, StepsProps, Typography } from "antd";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { BulkTransactionItem } from "../lib/Api";
import { BankAccountDropdown } from "./BankAccountDropdown";
import { Classifier, ClassifierProps, WorkingTransaction } from "./Classifier";
import { useDep } from "./DependencyContainerProvider";
import { LoadingSpinner } from "./LoadingSpinner";
import { useActiveBankAccount, useBankAccounts } from "./Providers";
import { ReportList } from "./ReportList";
import { ReportsPreview } from "./ReportsPreview";
import { RawTransaction, Uploader } from "./Uploader";
import { useOops } from "./handleError";

export interface DashboardPageProps {
  className?: string;
}

export function UploadPage(
  _props: React.PropsWithChildren<DashboardPageProps>,
) {
  const { api } = useDep();
  const navigate = useNavigate();
  const [saving, setSaving] = useState<boolean>(false);
  const [workingTransactions, setWorkingTransactions] = useState<
    WorkingTransaction[]
  >([]);
  const [workingTransactionsValid, setWorkingTransactionsValid] =
    useState<boolean>(false);

  const [modal, contextHolder] = Modal.useModal();
  const [uploading, setUploading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<RawTransaction[]>([]);
  const [dates] = useState<string[]>([]);
  const [current, setCurrent] = useState<number>(0);
  const [bankAccountId, setBankAccountId] = useActiveBankAccount();
  const [bankAccounts] = useBankAccounts();
  const oops = useOops();

  const resetState = () => {
    setCurrent(0);
    setTransactions([]);
    setWorkingTransactions([]);
  };

  useEffect(() => {
    if (bankAccountId || bankAccounts.length < 1) {
      return;
    }
    setBankAccountId(bankAccounts[0]?.id);
  }, [bankAccounts, bankAccountId]);

  const onChangeWorkingTransactions: ClassifierProps["onChange"] = (
    transactions,
    valid,
  ) => {
    setWorkingTransactions(transactions);
    setWorkingTransactionsValid(valid);
  };

  const renderContent = () => {
    switch (current) {
      case 0:
        return (
          <Uploader
            onLoading={setUploading}
            onGetResults={(trans) => {
              setTransactions(trans);
              setCurrent(current + 1);
            }}
          />
        );
      case 1:
        return (
          <div css={{ minHeight: "40vh", textAlign: "center" }}>
            <Typography.Title level={4}>
              Choose which account these transactions belong to:
            </Typography.Title>

            <BankAccountDropdown
              size="large"
              css={{ width: "14rem", marginBottom: "1rem", textAlign: "left" }}
              value={bankAccountId}
              bankAccounts={bankAccounts}
              onChange={setBankAccountId}
            />
          </div>
        );
      case 2:
        return (
          <Classifier
            onChange={onChangeWorkingTransactions}
            transactions={transactions}
          />
        );
      case 3:
        return <ReportsPreview dates={dates} />;
    }
  };

  const items: StepsProps["items"] = [
    { title: "Upload", icon: uploading ? <LoadingSpinner /> : null },
    { title: "Account" },
    { title: "Classify" },
  ];

  const canContinue = (): boolean => {
    switch (current) {
      case 0:
        return transactions.length > 0;
      case 1:
        return !!bankAccountId;
      case 2:
        return workingTransactionsValid;
      default:
        return false;
    }
  };

  const isLastStep = current === 2;

  const onFinish = () => {
    if (!bankAccountId) {
      console.error(
        "Oops! There was an error during this flow and the active bank account could not be find.",
      );
      oops();
      return;
    }
    const items: BulkTransactionItem[] = workingTransactions
      .filter((trans) => !trans.ignore)
      .map((trans) => {
        return {
          amount_in_cents: trans.amount! * 100,
          date: trans.date!.format("YYYY-MM-DD"),
          description: trans.description!,
          category_id: trans.category_id!,
        };
      });

    setSaving(true);
    api
      .createTransactionsBulk({
        transactions: items,
        bank_account_id: bankAccountId!,
      })
      .then(async (resp) => {
        const reports = await Promise.all(
          resp.dates.map((date) =>
            api
              .createMonthlyReport({ bank_account_id: bankAccountId!, date })
              .then((resp) => resp.monthly_report),
          ),
        );
        const query = qs.stringify({
          bankAccountId: bankAccountId,
        });
        const overviewUrl = `/overview?${query}`;
        const goToOverview = () => navigate(overviewUrl);

        modal.success({
          width: "60rem",
          icon: null,
          onCancel: () => goToOverview(),
          onOk: () => goToOverview(),
          title: "🎉 Nice!",
          footer(originNode, { OkBtn }) {
            return (
              <div css={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Text>
                  You can view this report at anytime from the{" "}
                  <Link to={overviewUrl}>overview</Link> page.
                </Typography.Text>
                <OkBtn />
              </div>
            );
          },
          okText: "Done",
          content: (
            <div>
              <ReportList reports={reports} />
            </div>
          ),
        });
      })
      .catch(oops)
      .finally(() => {
        setSaving(false);
      });
  };

  const onClickCancel = () => {
    modal.confirm({
      icon: null,
      title: "Confirm",
      content: "Are you sure you want to cancel this upload?",
      okText: "Yes, cancel",
      cancelText: "Nevermind",
      cancelButtonProps: {
        type: "text",
      },
      onOk: () => resetState(),
    });
  };

  return (
    <Fade>
      <Card>
        <div css={{ display: "flex", justifyContent: "center" }}>
          <div css={{ maxWidth: "48rem", width: "100%" }}>
            <Steps
              css={{ padding: "2rem 0" }}
              current={current}
              items={items}
            />

            <div css={{ minHeight: "40vh" }}>{renderContent()}</div>

            <div css={{ display: "flex", justifyContent: "space-between" }}>
              {current === 0 ? (
                <span />
              ) : (
                <Button type="text" onClick={onClickCancel}>
                  Cancel
                </Button>
              )}

              <div css={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                {current === 0 ? (
                  <span />
                ) : (
                  <Button onClick={() => setCurrent(current - 1)} type="text">
                    Prev
                  </Button>
                )}

                {isLastStep ? (
                  <Button
                    disabled={!canContinue()}
                    type={!canContinue() ? "text" : "primary"}
                    onClick={onFinish}
                    loading={saving}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    disabled={!canContinue()}
                    type={!canContinue() ? "text" : "primary"}
                    onClick={() => setCurrent(current + 1)}
                  >
                    Continue →
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {contextHolder}
      </Card>
    </Fade>
  );
}
