/** @jsxImportSource @emotion/react */
import { Table, TableProps } from "antd";
import React from "react";
import { CategoryStats } from "../lib/Api";
import { formatAmount } from "./lib";

export interface CategoryBreakdownTableProps {
  className?: string;
  stats: CategoryStats;
}

export function CategoryBreakdownTable(
  props: React.PropsWithChildren<CategoryBreakdownTableProps>,
) {
  const { stats } = props;
  const data = Object.entries(stats.totals)
    .map((entry) => {
      const [categoryId, total] = entry;
      return [stats.metadata[categoryId as any], total];
    })
    .sort((a, b) => {
      const [_aName, aTotal] = a;
      const [_bName, bTotal] = b;
      if (aTotal < bTotal) {
        return -1;
      }
      if (aTotal > bTotal) {
        return 1;
      }
      return 0;
    });

  console.log(`data: ${JSON.stringify(data)}`);

  const columns: TableProps["columns"] = [
    {
      title: "Category",
      render: (_, entry) => {
        const [name, total] = entry;
        return name;
      },
    },
    {
      title: "Total",
      render: (_, entry) => {
        const [name, total] = entry;
        return formatAmount(total);
      },
    },
  ];

  return <Table size="small" bordered dataSource={data} columns={columns} />;
}
