/** @jsxImportSource @emotion/react */

import { PropsWithChildren, createContext, useMemo } from "react";
import { useRequiredContext } from "./helpers";
import { useToken } from "./TokenProvider";
import { DependencyContainer } from "./DependencyContainer";

export interface DependencyContainerProviderProps extends PropsWithChildren {}

type ContextType = DependencyContainer;

const Context = createContext<ContextType | undefined>(undefined);

export function DependencyContainerProvider(
  props: DependencyContainerProviderProps,
) {
  const token = useToken();

  const value: DependencyContainer = useMemo(() => {
    return new DependencyContainer(token);
  }, [token]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

export function useDep(): ContextType {
  return useRequiredContext(Context);
}
