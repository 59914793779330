/** @jsxImportSource @emotion/react */
import { Typography } from "antd";
import React, { ReactNode } from "react";
import { AppColor } from "./design/colors";

export interface OnboardingCardProps {
  className?: string;
  icon: ReactNode;
  title: ReactNode;
  text: ReactNode;
  img?: string;
}

export function OnboardingCard(
  props: React.PropsWithChildren<OnboardingCardProps>,
) {
  return (
    <div
      css={{
        display: "flex !important",
        justifyContent: "center",
        height: "32rem",
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {props.icon}
        <Typography.Title
          level={3}
          css={{
            textAlign: "center",
            color: `${AppColor.Green13} !important`,
          }}
        >
          {props.title}
        </Typography.Title>
        <Typography.Paragraph
          css={{ fontSize: "1rem", color: AppColor.Green10 }}
        >
          {props.text}
        </Typography.Paragraph>
        {props.img && (
          <img
            height={120}
            css={{
              borderRadius: 4,
              boxShadow: "0px 6px 12px rgba(100,100,100,0.2)",
            }}
            src={props.img}
          />
        )}
      </div>
    </div>
  );
}
