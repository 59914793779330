import { useNavigate } from "react-router-dom";
import { useTokenSetter } from "./TokenProvider";
import { clearToken, storeToken } from "./storage";

interface LogoutFn {
  (): void;
}

function useLogout(): LogoutFn {
  const setToken = useTokenSetter();
  const navigate = useNavigate();

  return () => {
    setToken(null);
    clearToken();
    navigate("/logout");
  };
}

export default useLogout;
