/** @jsxImportSource @emotion/react */
import {
  Button,
  Card,
  Divider,
  List,
  message,
  Modal,
  Switch,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Account } from "../lib/Api";
import { AddPersonModal } from "./AddPersonModal";
import { useDep } from "./DependencyContainerProvider";
import { useMe, useUsers } from "./Providers";
import { useSettings } from "./SettingsProvider";
import { useOops } from "./handleError";

export interface AccountPageProps {
  className?: string;
}

export function AccountPage(props: React.PropsWithChildren<AccountPageProps>) {
  const { api } = useDep();
  const [me, setMe] = useMe();
  const [modal, contextHolder] = Modal.useModal();
  const [users] = useUsers();
  const [personModal, setPersonModal] = useState<boolean>(false);
  const oops = useOops();

  const { settings, setSetting } = useSettings();

  const onChange = (checked: boolean) => {
    setSetting("darkMode", checked);
  };

  const renderBilling = (account: Account) => {
    let renewDate;
    if (account.is_member && account.renew_date) {
      renewDate = dayjs.unix(account.renew_date).format("YYYY-MM-DD");
    }

    return (
      <>
        {contextHolder}

        <Typography.Title level={4}>Billing</Typography.Title>
        <List css={{ maxWidth: "20rem" }}>
          <List.Item>
            <Typography.Text>Member</Typography.Text>

            <Typography.Text>
              {account.is_member ? "Yes" : "No"}
            </Typography.Text>
          </List.Item>

          {account.is_member && (
            <>
              <List.Item>
                <Typography.Text>Renewal date</Typography.Text>

                <Typography.Text>
                  {account.auto_renew ? renewDate : `Ends on ${renewDate}`}
                </Typography.Text>
              </List.Item>

              <List.Item>
                <Typography.Text>Auto-renew</Typography.Text>

                <Typography.Text>
                  {account.auto_renew ? "Yes" : "No"}
                </Typography.Text>
              </List.Item>
            </>
          )}

          <List.Item>
            <></>

            {account.auto_renew && account.is_member && (
              <Button
                onClick={() => {
                  modal.confirm({
                    icon: null,
                    title: "Cancel subscription",
                    content: (
                      <Typography.Text>
                        Are you sure you want to cancel your subscription?
                      </Typography.Text>
                    ),
                    okButtonProps: {
                      danger: true,
                    },
                    cancelButtonProps: {
                      type: "text",
                    },
                    onOk: () => {
                      api
                        .updateAccount(account.id, { auto_renew: false })
                        .then((resp) => {
                          if (me) {
                            setMe({
                              account: resp.account,
                              user: me.user,
                            });
                            message.success(
                              "Your subscription has been cancelled and will not be renewed.",
                            );
                          }
                        })
                        .catch(oops);
                    },
                  });
                }}
              >
                Cancel subscription
              </Button>
            )}
          </List.Item>
        </List>
      </>
    );
  };

  return (
    <Card>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <div css={{ maxWidth: "60rem", width: "100%" }}>
          <Typography.Title level={4}>Settings</Typography.Title>

          <List css={{ maxWidth: "20rem" }}>
            <List.Item>
              <Typography.Text>Use dark mode</Typography.Text>
              <Switch
                size="small"
                checked={settings.darkMode}
                onChange={onChange}
              />
            </List.Item>
          </List>

          <Divider />

          {me?.account && renderBilling(me.account)}

          <Divider />

          <Typography.Title level={4}>People</Typography.Title>
          <List css={{ maxWidth: "20rem" }}>
            {users.map((user) => (
              <List.Item>
                <Typography.Text>{user.email}</Typography.Text>
              </List.Item>
            ))}
            <List.Item>
              <div>
                <div css={{ paddingBottom: "1rem" }}>
                  <Button onClick={() => setPersonModal(true)}>
                    Add person
                  </Button>
                </div>
                <Typography.Text type="secondary">
                  Add another person to this account.
                </Typography.Text>
              </div>
            </List.Item>
          </List>
        </div>
      </div>
      <AddPersonModal
        open={personModal}
        onDone={() => setPersonModal(false)}
        onCancel={() => setPersonModal(false)}
      />
    </Card>
  );
}
