/** @jsxImportSource @emotion/react */
import React from "react";
import { Outlet } from "react-router-dom";
import { AppThemeProvider } from "./AppThemeProvider";
import { DependencyContainerProvider } from "./DependencyContainerProvider";
import { Locales } from "./Locales";
import { UserLocaleProvider } from "./Providers";
import { SettingsProvider } from "./SettingsProvider";
import { TokenProvider } from "./TokenProvider";
import { getToken } from "./storage";

export interface MainProps {
  className?: string;
}

export function Main(props: React.PropsWithChildren<MainProps>) {
  return (
    <UserLocaleProvider>
      <Locales>
        <TokenProvider defaultValue={getToken()}>
          <DependencyContainerProvider>
            <SettingsProvider>
              <AppThemeProvider>
                <Outlet />
              </AppThemeProvider>
            </SettingsProvider>
          </DependencyContainerProvider>
        </TokenProvider>
      </Locales>
    </UserLocaleProvider>
  );
}
